<template>
  <div class="container1">
    <div class="slide-down">
      <div class="item-wrapper">
        <div
          v-for="drug in drugList"
          class="item"
          :class="{ item_selected: drug.productId === currentDrug.productId }"
          :key="drug.productId"
          @click="handleSelectDrug(drug)"
        >
          {{ drug.productName }}
        </div>
      </div>

      <div class="btns">
        <button class="clear" @click="handleClear">清空</button>
        <button class="confirm" @click="handleConfirm">确定</button>
      </div>
    </div>
    <div class="mask" @click="handleClickMask"></div>
  </div>
</template>

<script>
import { Toast } from 'vant';

export default {
  name: 'medicine-select',
  data () {
    return {
      currentDrug: {},
    };
  },

  props: {
    drugList: {
      type: Array,
      required: true,
      default () {
        return []
      }
    }
  },
  methods: {
    handleClickMask () {
      this.$emit('close')
    },

    // 选择药品
    handleSelectDrug (drug) {
      this.currentDrug = drug;
    },

    // 清空
    handleClear () {
      this.currentDrug = {};
    },

    // 点击确定
    handleConfirm () {
      if (!this.currentDrug) {
        return Toast.fail('请选择药品');
      } else {
        console.log('confirm');
        this.$emit('selectdrug', this.currentDrug);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-down {
  padding: 20px 16px 20px 16px;
  box-sizing: border-box;

  position: fixed;
  //   top: -200px;
  top: 0;
  left: 0;
  width: 100%;
  //   height: 200px;
  background-color: #fff;
  transition: transform 0.5s ease-out;
  //   transform: translateY(0);
  z-index: 1000;

  .item-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-gap: 4px;
    grid-row-gap: 10px;

    .item {
      width: 80px;
      height: 36px;
      background: #f5f5f5;
      border-radius: 6px;

      // height: 36px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
      text-align: center;
    }

    .item_selected {
      background: #f36218;
      color: #fff;
    }
  }

  .btns {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    .clear {
      width: 160px;
      height: 44px;
      background: #ffffff;
      border-radius: 40px;
      border: 1px solid #f36218;
      color: #f36218;
      line-height: 44px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 8px;
    }
    .confirm {
      width: 160px;
      height: 44px;
      background: #f36218;
      border-radius: 40px;
      color: #fff;
      border: none;
      line-height: 44px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 8px;
    }
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999; /* 确保蒙层在 .slide-down 上方 */
}
</style>