<template>
  <div class="infusion-detail">
    <StatusInfo :info="statusInfo" :title="statusText" />

    <InfisionTimeSelect
      v-if="isAudit"
      :isReview="true"
      :daysList="daysList"
      title="修改预约时间"
      tip="如时间冲突，请与患者及时沟通"
      @selectTime="handleSelectTime"
      :infusionDate="this.infusionDate"
      :infusionTimeCode="this.infusionTimeCode"
      ref="myComponentRef"
    />

    <CardInfoKv
      :imgPath="baseImgPath"
      @emitPhone="emitPhone"
      :infoArray="[
        {
          key: '姓名：',
          value: applyInfo.patientName
            ? desensitizeName(applyInfo.patientName)
            : '',
        },
        {
          key: '联系方式：',
          value: `
          <div class='infusion-contacts'>
            <div>
              ${
                applyInfo.patientPhone
                  ? desensitizePhone(applyInfo.patientPhone)
                  : ''
              }
            </div>
            <div>${htmlTest}</div>
          </div>`,
        },
        { key: '年龄：', value: applyInfo.patientAge },
      ]"
      title="患者信息"
    />

    <div class="basic_info" v-if="selectedDrugs.length > 0">
      <div class="title">
        <img class="drug_img" src="~@img/cardInfo/药品信息.svg" alt="" />
        <span>药品信息</span>
      </div>
      <div class="group">
        <SelectedDrugItem
          v-for="item in selectedDrugs"
          :key="item.goodsId"
          :item="item"
          :swipeDisabled="true"
        />
      </div>
    </div>

    <CardInfoKv
      :imgPath="otherImgPath"
      :infoArray="[
        { key: '疾病诊断：', value: applyInfo.diagnoseName },
        {
          key: '给药方式：',
          value: `${infusionTypeEnum[infusionType]}`,
        },
        {
          key: '特殊备注：',
          value: applyInfo.remark,
        },
      ]"
      title="其他信息"
    />

    <!-- 上传图片 -->
    <Uploader
      v-if="showUploader"
      :needEdit="false"
      :onlyShow="true"
      :photoList="photoList"
      :needSupplementary="false"
    />

    <Action-Sheet v-model="isShow" :round="false" title="请选择拒收原因">
      <div class="infusion-content">
        <InfusionRadio
          :radio="radio"
          :radioArray="[
            {
              value: '1',
              label: '患者提供资料不全',
            },
            {
              value: '2',
              label: '预约时间冲突',
            },
            {
              value: '3',
              label: '输注中心暂无法输注此药品',
            },
            {
              value: '4',
              label: '其他',
            },
          ]"
          @onRadioChange="onRadioChange"
        />

        <div class="body" v-if="radio === '4'">
          <textarea
            v-model.trim="content"
            class="textarea"
            placeholder="请填写详细原因"
            maxlength="50"
          ></textarea>
        </div>
        <BtnBottom
          :fixed="true"
          @clickEvent="onConfirmReject"
          title="确定拒收"
        />
      </div>
    </Action-Sheet>

    <div v-if="isAudit">
      <BottomBtns
        :btns="[
          {
            text: '拒收',
            type: 'plain',
            eventName: 'onReject',
          },
          {
            text: '通过',
            type: 'primary',
            eventName: 'onSave',
          },
        ]"
        @onSave="onSave"
        @onReject="onReject"
      />
    </div>
  </div>
</template>

<script>
import WxTool from '@/utils/wx-tool';
import StatusInfo from '@/components/status-info';
import CardInfoKv from '@/components/card-info-kv';
import BtnBottom from '@/components/btn-bottom';
import Uploader from '@/components/uploader';
import { ActionSheet } from 'vant';
import InfisionTimeSelect from '@/components/infusion-time-select';
import BottomBtns from '@/components/bottom-btns/index.vue';
import InfusionRadio from '@/components/infusion-radio';
import SelectedDrugItem from '@/components/selectedDrugItem/index.vue'
import api from '@/api'
import { getQueryStringArgs } from '@/utils';
import { Dialog, Toast } from 'vant';
import { desensitizePhone, desensitizeName, computeAfterDay } from '@/utils';

export default {
  name: 'InfusionCenterDetail',
  components: {
    StatusInfo,
    CardInfoKv,
    BtnBottom,
    InfisionTimeSelect,
    ActionSheet,
    BottomBtns,
    InfusionRadio,
    Uploader,
    SelectedDrugItem
  },
  data () {
    return {
      baseImgPath: require('../../assets/images/cardInfo/基本信息.svg'),
      otherImgPath: require('../../assets/images/cardInfo/其他信息.svg'),
      htmlTest: `
                <a class="href"> 
                  <span class="icon">
                    <img src="${require('./images/telephone@2x.png')}" />
                  </span>
                  <span>联系患者</span>
                </a>
            `,
      imgPath: './images/telephone@2x.png',
      desensitizePhone,
      desensitizeName,
      isShow: false,
      content: '',
      radio: '1',
      isAudit: false,
      queryObj: {},
      daysList: [],
      applyInfo: {},
      statusText: '',
      statusInfo: '等待输注中心审核',
      infusionDate: '',
      infusionTime: '',
      infusionTimeCode: '',
      infusionWeek: '',
      infusionDateRecord: '',
      infusionTimeRecord: '',
      infusionTypeEnum: {
        '1': '静脉输注',
        '2': '皮下输注'
      },
      infusionType: '',
      infusionCenterCode: '',
      phoneNumber: '',
      isShowPopup: false,
      photoList: [],
      localIds: [],
      LocalImgData: [],
      selectedDrugs: [],
      showUploader: false,
    };
  },

  created () {
    const id = this.$route.query.id
    this.getDetail(id)
    this.queryObj = getQueryStringArgs()
  },

  methods: {
    emitPhone () {
      window.location.href = `tel:${this.phoneNumber}`
    },
    async onAudit (status) {
      const param = {
        id: this.$route.query.id,
        infusionDate: this.infusionDate,
        infusionTime: this.infusionTime,
        infusionTimeCode: this.infusionTimeCode,
        infusionWeek: this.infusionWeek,
        rejectComment: status === 2 ? '' : this.content,
        rejectType: status === 2 ? '' : this.radio,
        status: status,
        infusionCenterCode: this.infusionCenterCode
      }
      try {
        const res = await api.centerApplyAudit(param);
        if (res?.code === '0') {
          if (status === 3) { this.isShow = false; }
          // Toast.success('审核成功!');
          this.$router.push({
            path: '/infusion',
          });
        }
      } catch (err) {
        console.log(err, 'ree')
      }
    },

    async onSave () {
      const childComponent = this.$refs.myComponentRef;
      const { isFully, isTimeBeyond } = childComponent;
      console.log(childComponent, isFully, isTimeBeyond)
      if (isFully) {
        return Toast('当前预约时段人数已满，请重新选择!')
      }
      if (isTimeBeyond) {
        return Toast('当前预约时段已过，请重新选择!')
      }
      if (!this.infusionDateRecord || !this.infusionTimeRecord) {
        return Toast('请选择预约时间!')
      }
      Dialog.confirm({
        title: '确认',
        message: '确认预约信息无误？'
      }).then(async () => {
        this.onAudit(2)
      }).catch((err) => {
        console.log(err, 'ree')
      })
    },

    onReject () {
      this.isShow = true;
    },

    async onConfirmReject () {
      this.onAudit(3)
    },

    async getDetail (id) {
      const res = await api.centerApplyDetail({ id })
      if (res.code === '0' && res.data) {
        this.getTime(res.data)
        this.applyInfo = res.data;
        this.infusionType = res.data.infusionType;
        this.infusionTime = res.data.infusionTime;
        this.infusionCenterCode = res.data.infusionCenterCode;
        this.infusionTimeCode = res.data.infusionTimeCode;
        this.infusionWeek = res.data.infusionWeek;
        this.infusionDate = res.data.infusionDate ? res.data.infusionDate.split(' ')[0] : '';
        this.getInfusionStatus(res.data.infusionStatus)
        this.statusInfo = `预约输注时间：${res.data.infusionDate ? res.data.infusionDate.split(' ')[0] : ''} ${res.data.infusionWeek} ${res.data.infusionTime}`
        this.isAudit = +res.data.infusionStatus === 1
        this.phoneNumber = this.applyInfo.patientPhone
        this.photoList = res.data && res.data.infusionImages || []
        this.selectedDrugs = res.data && res.data.goodDTOs && res.data.goodDTOs || []
        // 预约日在今天之前的，不展示资料模块
        this.showUploader = !computeAfterDay(res.data.infusionDate.split(' ')[0])
      }
    },

    // 获取预约输注时间
    async getTime ({ infusionCenterCode, infusionDate, infusionTime }) {
      const res = await api.getAvaibleApplyTime({
        infusionCenterCode
      })
      this.daysList = res.data;
      const infusionDateT = infusionDate ? infusionDate.split(' ')[0] : '';
      const isSelectDate = res && res.data && res.data.find((item => item.date === infusionDateT))
      if (isSelectDate) {
        this.infusionDateRecord = infusionDateT;
        this.infusionTimeRecord = infusionTime;
      } else {
        this.infusionDateRecord = '';
        this.infusionTimeRecord = ''
      }
    },

    getInfusionStatus (status) {
      if (Number(status) === 1) {
        this.statusText = '待审核';
      }
      if (Number(status) === 2) {
        this.statusText = '预约成功';
      }
      if (Number(status) === 3) {
        this.statusText = '预约失败';
      }
      if (Number(status) === 4) {
        this.statusText = '已取消';
      }
    },

    // 确认选择时间
    handleSelectTime (day, time) {
      this.infusionDate = day.date
      this.infusionTime = time.time
      this.infusionTimeCode = time.timeCode
      this.infusionWeek = day.week
      this.infusionDateRecord = day.date
      this.infusionTimeRecord = time.time
    },

    onRadioChange (radio) {
      this.radio = radio
    },
  },
};
</script>
<style lang="scss">
.infusion-contacts {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  .href {
    color: #3c6bf0 !important;
    text-decoration: none !important;
    cursor: pointer;

    .icon {
      width: 14px;
      height: 14px;
      img {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.infusion-detail {
  width: 100%;
  padding-bottom: 90px;
  .fixed {
    position: fixed;
    width: 100%;
    z-index: 11;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 0 18px;
    height: 44px;
    background: #fff;
    // box-shadow: 0px 0px 8px 0px rgba(60, 107, 240, 0.1);

    .search {
      display: flex;
      padding: 2px 0;
      flex: 1;
      background: #f5f7fa;
      border-radius: 100px;
      &_icon_box {
        padding: 2px 8px 0 12px;
        height: 20px;
        line-height: 1;
      }

      &_placeholder_box {
        height: 0.7rem;
        min-height: 0.7rem;
      }

      &_icon {
        width: 20px;
        height: 20px;
      }
      &_placeholder {
        // margin-left: 16px;
        font: 200 14px/25px PingFangSC-Regular, PingFang SC;
        color: #babfc9;
      }
    }
  }
}
.infusion-content {
  padding-bottom: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .choose {
    padding: 9px 12px;
  }
  .body {
    display: flex;
    justify-content: center;
    .textarea {
      width: 86%;
      height: 133px;
      border: none;
      background: #f5f7fa;
      border-radius: 6px;
      resize: none;
      font-size: 16px;
      font-weight: 400;
      padding: 12px;
      &::placeholder {
        color: #c2c6d0;
      }
    }
  }

  /deep/ .van-radio {
    padding-bottom: 15px;
  }
  /deep/ .van-radio__label {
    font-size: 16px;
  }
  /deep/ .van-radio__icon {
    width: 20px;
    height: 20px;
  }
}

.basic_info {
  margin: 8px 0;
  .title {
    background: #fff;
    color: #3a3a3a;
    font-weight: 500;
    font-size: 14px;
    height: 18px;
    line-height: 18px;
    padding: 15px 18px 0px 18px;
    .drug_img {
      height: 18px;
      vertical-align: text-bottom;
    }
  }
  .group {
    padding: 0px 3px;
  }
}
</style>
