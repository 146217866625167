<template>
  <div class="container">
    <div class="fixed">
      <!-- 静态 -->
      <div v-if="stage === 0" class="header">
        <div @click="toSearch" class="search">
          <div class="search_icon_box">
            <img class="search_icon" src="./images/magnifier@2x.png" alt="" />
          </div>
          <div class="search_placeholder_box">
            <span class="search_placeholder">请输入患者或药品名称</span>
          </div>
        </div>
      </div>

      <div v-if="stage === 1 || stage === 2" class="headerReal">
        <div class="search_outer">
          <div class="search_component">
            <div class="icon_search">
              <img class="icon_img" src="./images/magnifier@2x.png" alt="" />
            </div>
            <div class="search_box">
              <input
                @input="onInput"
                @focus="onFocus"
                v-model="searchKey"
                type="text"
                placeholder="请输入患者或药品名称"
                class="input"
              />
            </div>
            <div v-if="searchKey" @click="onClear" class="icon_delete">
              <img class="icon_img" src="./images/btn_delete@2x.png" alt="" />
            </div>
          </div>
        </div>
        <div class="search_text">
          <span v-if="searchKey" @click.stop="handleSearch" class="text">
            搜索
          </span>
          <span v-else="!searchKey" @click.stop="cancelSearch" class="text">
            取消
          </span>
        </div>
      </div>
      <InfusionTab :tabs="tabs" :active="active" @switchTab="switchTab" />
    </div>
    <div ref="tabContent" class="pTop88">
      <InfusionList
        :list="list"
        :isTel="true"
        :loading="loading"
        :finished="finished"
        :refreshing="refreshing"
        :isInfusion="true"
        @toDetail="toDetail"
        @onLoad="onLoads"
        @onRefresh="onRefresh"
        @toAudit="toAudit"
      />
    </div>
    <MedFeb />
    <div v-if="mask" @click="cancelSearch" class="keyboard_mask"></div>
  </div>
</template>

<script>
import InfusionTab from '@/components/infusion-tab';
import InfusionList from '@/components/infusion-list';
import MedFeb from '@/components/med-fab';
import api from '@/api';

export default {
  name: 'Infusion',
  components: {
    InfusionTab,
    InfusionList,
    MedFeb,
  },
  data () {
    return {
      stage: 0,
      mask: false,
      searchKey: '',
      active: '0',
      tabs: [
        { key: '0', title: '全部', total: 0 },
        { key: '1', title: '待审核', total: 0 },
        { key: '2', title: '预约成功', total: 0 },
        { key: '3', title: '已取消/预约失败', total: 0 },
      ],
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNumber: 1,
      isScroll: false,
    };
  },

  computed: {
    activeKey () {
      return this.$route.query.active;
    },
  },
  mounted () {
    this.$refs.tabContent.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    this.$refs.tabContent.removeEventListener('scroll', this.handleScroll);
  },
  created () {
    this.getTabNum();
    this.active = this.activeKey || '0';
    this.getInfusionList();
  },
  methods: {
    async getTabNum () {
      const res = await api.getCenterCount({
        infusionCenterCode: JSON.parse(localStorage.getItem('userInfo') || '{}').infusionCenterCode || 'IC240418135649',
        pageNumber: 1,
        pageSize: 1000,
        status: [],
      });

      const {
        allCount, auditCount, successCount, cancelAndFailedCount,
      } = res.data;
      this.tabs[0].total = allCount;
      this.tabs[1].total = auditCount;
      this.tabs[2].total = successCount;
      this.tabs[3].total = cancelAndFailedCount;
    },

    activeTabs () {
      if (this.active === '0') {
        return ['1', '2', '3', '4'];
      } if (this.active === '3') {
        return ['3', '4'];
      }
      return [this.active];
    },

    async getInfusionList (type) {
      const param = {
        status: this.activeTabs(),
        pageNumber: this.pageNumber,
        pageSize: 10,
        keywords: this.searchKey,
        infusionCenterCode: JSON.parse(localStorage.getItem('userInfo') || '{}').infusionCenterCode || 'IC240418135649',
      };
      const res = await api.getCenterList(param);
      if (res?.code === '0') {
        if (res?.data?.list && Array.isArray(res.data.list)) {
          res.data.list.forEach((item) => {
            item.infusionDate = item.infusionDate ? item.infusionDate.split(' ')[0] : '';
          });
        }
        const nArr = (res.data && res.data.list) || [];
        this.list = type === 'search' ? (nArr || []) : this.list.concat(nArr);
        if (type === 'search' && nArr.length > 0) {
          this.isScroll = false;
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
      const { totalPage, pageNumber } = res.data;
      //   // 非最后一页逻辑
      if (Number(pageNumber) < Number(totalPage)) {
        this.pageNumber += 1;
        this.finished = false;
        this.loading = false;
      }

      // 最后一页逻辑
      if (totalPage === pageNumber) {
        this.finished = true;
        this.loading = false;
      }
      this.getTabNum();
    },
    handleScroll () {
      if (!this.isScroll) return;
      if (this.$refs.tabContent.scrollTop > 0) {
        this.$refs.tabContent.scrollTo(0, 0);
      }
    },
    toSearch () {
      this.stage = 1;
      this.mask = true;
    },
    onInput (e) {
      this.searchKey = e.target.value;
    },
    onFocus () {
      this.mask = true;
    },
    onClear () {
      setTimeout(() => { this.searchKey = ''; }, 100);
    },
    // 搜索按钮触发搜索
    handleSearch () {
      this.stage = 2;
      this.mask = false;
      this.$refs.tabContent.scrollTo(0, 0);
      this.isScroll = true;
      this.pageNumber = 1;
      this.getInfusionList('search');
    },

    cancelSearch () {
      this.stage = 0;
      this.mask = false;
      this.searchKey = '';
      this.pageNumber = 1;
      this.isScroll = false;
      this.getInfusionList('search');
    },
    switchTab (index, item) {
      if (this.loading) {
        return;
      }
      this.active = item.key;
      this.pageNumber = 1;
      if (this.$refs.tabContent.scrollTop > 0) {
        this.$refs.tabContent.scrollTo(0, 0);
      }
      this.getInfusionList('search');
    },
    toAudit (item) {
      this.$router.push({
        path: '/infusion/detail',
        query: {
          id: item.id,
        },
      });
    },
    toDetail (item) {
      this.$router.push({
        path: '/infusion/detail',
        query: {
          id: item.id,
        },
      });
    },
    onLoads () {
      this.loading = true;
      this.getInfusionList();
    },
    onRefresh () {
      console.log('refresh');

      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pageNumber = 1;
      this.getInfusionList('search');
      if (!this.list.length) {
        this.finished = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  margin-bottom: env(safe-area-inset-bottom);
  position: relative;
  overflow-y: auto;
  .fixed {
    // position: fixed;
    position: sticky;
    width: 100%;
    z-index: 11;
    height: 84px;
    top: 0;
  }
  .header {
    display: flex;
    align-items: center;
    padding: 0 18px;
    height: 44px;
    background: #fff;
    .search {
      display: flex;
      padding: 2px 0;
      flex: 1;
      background: #f5f7fa;
      border-radius: 100px;
      &_icon_box {
        padding: 2px 8px 0 12px;
        height: 20px;
        line-height: 1;
      }

      &_placeholder_box {
        height: 0.7rem;
        min-height: 0.7rem;
      }

      &_icon {
        width: 20px;
        height: 20px;
      }
      &_placeholder {
        font: 200 14px/25px PingFangSC-Regular, PingFang SC;
        color: #babfc9;
      }
    }
  }
}

.headerReal {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 8px;
  // width: 100%;
  height: 44px;
  background: #fff;
  box-shadow: 0px 0px 4px 4px rgba(60, 107, 240, 0.05);
  .search_outer {
    flex: 1;

    .search_component {
      display: flex;
      padding: 4px 0;
      background: #f5f7fa;
      border-radius: 100px;
      flex: 1;

      .icon_search {
        padding: 0 8px 0 12px;
        height: 20px;
        line-height: 1;
      }
      .icon_delete {
        padding: 0 4px 0 0;
        height: 20px;
        line-height: 1;
      }
      .icon_img {
        width: 20px;
        height: 20px;
      }
      .search_box {
        flex: 1;
        line-height: 1;

        .input {
          width: 250px;
          height: 20px;
          border: none;
          background: #f5f7fa;
          font-size: 14px;
          font-weight: 400px;
          line-height: 20px;
        }
        .input::placeholder {
          color: #babfc9;
        }
      }
    }
  }

  .search_text {
    width: 60px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    .text {
      font: 200 14px PingFangSC-Medium, PingFang SC;
      color: #3679f0;
    }
  }
}
.keyboard_mask {
  position: fixed;
  top: 44px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1100;
}

.pTop88 {
  max-height: calc(100vh - 84px);
  overflow-y: auto;
}
</style>
