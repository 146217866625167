var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"infusion-detail"},[_c('StatusInfo',{attrs:{"info":_vm.statusInfo,"title":_vm.statusText}}),(_vm.isAudit)?_c('InfisionTimeSelect',{ref:"myComponentRef",attrs:{"isReview":true,"daysList":_vm.daysList,"title":"修改预约时间","tip":"如时间冲突，请与患者及时沟通","infusionDate":this.infusionDate,"infusionTimeCode":this.infusionTimeCode},on:{"selectTime":_vm.handleSelectTime}}):_vm._e(),_c('CardInfoKv',{attrs:{"imgPath":_vm.baseImgPath,"infoArray":[
      {
        key: '姓名：',
        value: _vm.applyInfo.patientName
          ? _vm.desensitizeName(_vm.applyInfo.patientName)
          : '',
      },
      {
        key: '联系方式：',
        value: ("\n        <div class='infusion-contacts'>\n          <div>\n            " + (_vm.applyInfo.patientPhone
                ? _vm.desensitizePhone(_vm.applyInfo.patientPhone)
                : '') + "\n          </div>\n          <div>" + _vm.htmlTest + "</div>\n        </div>"),
      },
      { key: '年龄：', value: _vm.applyInfo.patientAge } ],"title":"患者信息"},on:{"emitPhone":_vm.emitPhone}}),(_vm.selectedDrugs.length > 0)?_c('div',{staticClass:"basic_info"},[_vm._m(0),_c('div',{staticClass:"group"},_vm._l((_vm.selectedDrugs),function(item){return _c('SelectedDrugItem',{key:item.goodsId,attrs:{"item":item,"swipeDisabled":true}})}),1)]):_vm._e(),_c('CardInfoKv',{attrs:{"imgPath":_vm.otherImgPath,"infoArray":[
      { key: '疾病诊断：', value: _vm.applyInfo.diagnoseName },
      {
        key: '给药方式：',
        value: ("" + (_vm.infusionTypeEnum[_vm.infusionType])),
      },
      {
        key: '特殊备注：',
        value: _vm.applyInfo.remark,
      } ],"title":"其他信息"}}),(_vm.showUploader)?_c('Uploader',{attrs:{"needEdit":false,"onlyShow":true,"photoList":_vm.photoList,"needSupplementary":false}}):_vm._e(),_c('Action-Sheet',{attrs:{"round":false,"title":"请选择拒收原因"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('div',{staticClass:"infusion-content"},[_c('InfusionRadio',{attrs:{"radio":_vm.radio,"radioArray":[
          {
            value: '1',
            label: '患者提供资料不全',
          },
          {
            value: '2',
            label: '预约时间冲突',
          },
          {
            value: '3',
            label: '输注中心暂无法输注此药品',
          },
          {
            value: '4',
            label: '其他',
          } ]},on:{"onRadioChange":_vm.onRadioChange}}),(_vm.radio === '4')?_c('div',{staticClass:"body"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.content),expression:"content",modifiers:{"trim":true}}],staticClass:"textarea",attrs:{"placeholder":"请填写详细原因","maxlength":"50"},domProps:{"value":(_vm.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),_c('BtnBottom',{attrs:{"fixed":true,"title":"确定拒收"},on:{"clickEvent":_vm.onConfirmReject}})],1)]),(_vm.isAudit)?_c('div',[_c('BottomBtns',{attrs:{"btns":[
        {
          text: '拒收',
          type: 'plain',
          eventName: 'onReject',
        },
        {
          text: '通过',
          type: 'primary',
          eventName: 'onSave',
        } ]},on:{"onSave":_vm.onSave,"onReject":_vm.onReject}})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('img',{staticClass:"drug_img",attrs:{"src":require("@img/cardInfo/药品信息.svg"),"alt":""}}),_c('span',[_vm._v("药品信息")])])}]

export { render, staticRenderFns }