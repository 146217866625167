<template>
  <div class="wrapper">
    <div class="header">
      <div class="userinfo">
        <img class="avatar" :src="require('./tax-avatar.png')" alt="" />
        <span class="username">微信用户{{ userId }}</span>
        <span class="tel">{{ phone && maskPhone(phone) }}</span>
        <!-- <img
          :src="require('./btn_arrow_right_grey@2x.png')"
          alt=""
          class="arrow"
        /> -->
      </div>

      <div class="record" @click="handleToRecord">
        <img :src="require('./record@2x.png')" alt="" class="logo" />
        <span class="title">预约记录</span>
        <img
          :src="require('./btn_arrow_right_grey@2x.png')"
          alt=""
          class="arrow"
        />
      </div>
    </div>

    <div class="operation">
      <div class="item" @click="handleClickAgreement(0)">预约输注服务协议</div>
      <div class="item" @click="handleClickAgreement(1)">隐私协议</div>
      <div class="item" @click="handleProcess">输注预约流程</div>
      <div class="item" @click="handleLogOff">注销账户</div>
    </div>

    <div class="logout">
      <button class="btn" @click="handleLogout">退出登录</button>
    </div>
    <MedFeb />
    <van-action-sheet v-model="show" title="派输注协议及政策">
      <AgreementContent :active="active" />
    </van-action-sheet>
  </div>
</template>

<script>
import MedFeb from '@/components/med-fab';
import AgreementContent from '@/components/agreementContent'
import { Dialog, Toast } from 'vant'
import api from '@/api'

export default {
  components: {
    MedFeb,
    AgreementContent
  },
  data () {
    return {
      show: false,
      active: 0,
      // nickName: '',
      userId: '',
      phone: ''
    }
  },
  created () {
    console.log('localStorage.getItem', localStorage.getItem('userInfo'))
    if (!localStorage.getItem('userInfo')) {
      // 没有用户信息，跳转到登录页面
      this.$router.replace('/login')
      // this.client = 'patient'
    } else {
      this.client = Number(JSON.parse(localStorage.getItem('userInfo') || '{}').userRole) === 0 ? 'patient' : 'center';
    }
    this.userId = JSON.parse(localStorage.getItem('userInfo')).userId
    this.phone = JSON.parse(localStorage.getItem('userInfo')).phone
  },
  methods: {
    handleClickAgreement (active) {
      this.active = active
      this.show = true
    },
    maskPhone (phone) {
      return phone.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
    },
    // 预约记录
    handleToRecord () {
      this.$router.push({
        path: this.client === 'patient' ? '/applyList' : '/infusion'
      })
    },

    handleLogout () {
      Dialog.confirm({
        title: '提示',
        message: '确认退出登录',
      })
        .then(async () => {
          const res = await api.logout({})
          if (res.code === '0') {
            Toast.success('退出成功')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('userToken')
            this.$router.replace('/login')
          }
        })
        .catch(() => {
          // on cancel
        });
    },

    handleProcess () {
      this.$router.push('/instructions');
    },

    handleLogOff () {
      this.$router.push('/usercenter/logoff');
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  background: #f5f7fa;
  .header {
    width: 100%;
    height: 131px;
    background: #fff;
    padding: 20px 12px 0 12px;
    box-sizing: border-box;

    .userinfo {
      width: 100%;
      height: 52px;
      // background-color: yellowgreen;
      position: relative;
      .avatar {
        position: absolute;
        top: 0;
        left: 4px;
        width: 50px;
        height: 50px;
      }

      .username {
        position: absolute;
        top: 0;
        left: 62px;

        // width: 80px;
        height: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        line-height: 28px;
        // text-align: left;
        font-style: normal;
      }

      .tel {
        position: absolute;
        bottom: 0;
        left: 62px;

        height: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 20px;
        text-align: left;
        font-style: normal;
      }

      .arrow {
        position: absolute;
        top: 20px;
        right: 6px;
        width: 12px;
        height: 12px;
      }
    }

    .record {
      margin-top: 18px;
      width: 350px;
      height: 50px;

      background: #cfe1fd;
      border-radius: 6px;
      position: relative;

      .logo {
        position: absolute;
        top: 12px;
        left: 16px;
        width: 26px;
        height: 26px;
      }

      .title {
        position: absolute;
        top: 14px;
        left: 55px;
        height: 22px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #1a3377;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }

      .arrow {
        position: absolute;
        top: 19px;
        right: 10px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .operation {
    width: 100%;
    height: 203px;
    background: #fff;
    margin-top: 18px;
    padding: 0 18px;
    box-sizing: border-box;
    .item {
      height: 50px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      font-weight: 400px;
      font-size: 15px;
      font-style: normal;
      display: flex;
      align-items: center;
    }
  }

  .logout {
    width: 100%;
    margin-top: 18px;
    padding: 0 18px;
    box-sizing: border-box;
    background-color: #fff;
    .btn {
      width: 100%;
      height: 50px;
      background-color: #fff;
      color: #f36218;
      border: none;
      font-size: 15px;
    }
  }
  .van-action-sheet {
    max-height: 95% !important;
  }
}
</style>