<template>
  <div class="choose">
    <van-radio-group v-model="internalRadio" @change="onRadioChange">
      <van-radio
        v-for="(item, index) in radioArray"
        :key="index"
        :name="item.value"
        checked-color="#f36218"
      >
        {{ item.label }}
      </van-radio>
    </van-radio-group>
  </div>
</template>
<script>

export default {
  name: 'InfusionRadio',
  props: {
    radio: {
      required: true,
      default: '',
      type: String,
    },
    radioArray: {
      required: true,
      default: () => [],
      type: Array,
    },
  },
  data () {
    return {
      internalRadio: this.radio,
    };
  },
  methods: {
    onRadioChange (name) {
      this.$emit('onRadioChange', name);
    },
  },
};
</script>

<style lang="scss" scoped>
.choose {
  padding: 9px 12px;
}
</style>
