<template>
  <div class="container">
    <!-- 头部 -->
    <div class="header">
      <span
        v-if="client === 'patient'"
        @click="handleSelectCity"
        class="city_name"
        >{{ cityName || '定位失败' }}</span
      >
      <div
        v-if="client === 'patient'"
        @click="handleClickSearch"
        class="search"
      >
        <img class="magnifier" src="./magnifier@2x.png" alt="" />
        <span class="tip">搜索输注中心</span>
      </div>

      <span v-if="client === 'center'" class="center_name">
        {{ centerName }}
      </span>

      <div @click="handleShowProcess" class="process">
        <img :src="require('./process@2x.png')" class="process_img" alt="" />
        <span class="tip">查看输注预约流程</span>
        <img
          :src="require('./arrow_right@2x.png')"
          class="arrow_right"
          alt=""
        />
      </div>
    </div>

    <!-- 两个入口 -->
    <div class="entrance">
      <div @click="handleToRecord" class="item">
        <span class="title">预约记录</span>
        <img :src="require('./record@2x.png')" class="record" alt="" />
      </div>
      <div @click="handleUserCenter" class="item">
        <span class="title">个人中心</span>
        <img :src="require('./usercenter@2x.png')" class="usercenter" alt="" />
      </div>
    </div>

    <!-- 输注中心列表 患者端显示 -->
    <div v-if="client === 'patient'" class="list">
      <div class="drug_select">
        <p class="line">
          <span @click="handleClickSelectDrug" class="title">{{
            selectedDrug.productName || '全部药品'
          }}</span>
          <span class="clear" @click="handleClear">重置条件</span>
        </p>

        <img
          v-if="infusionCenterList && infusionCenterList.length === 0"
          :src="require('./empty.png')"
          class="empty_img"
          alt=""
        />
      </div>
      <p
        v-if="infusionCenterList && infusionCenterList.length === 0"
        class="empty_text"
      >
        暂无输注中心
      </p>
      <infusion-center-item
        v-for="infusion in infusionCenterList"
        :key="infusion.id"
        :infusionObj="infusion"
        @apply="handleApply"
        @detail="handleDetail"
      />
    </div>

    <!-- 常用功能-审核 中心端显示 -->
    <div v-if="client === 'center'" class="review_wrapper">
      <p class="title">常用功能</p>
      <div @click="handleToList" class="review">
        <img :src="require('./wait_review@2x.png')" class="logo" alt="" />
        <span class="text">审核</span>
        <div v-if="Number(waitReviewCount) > 0" class="badge_wrapper">
          <van-badge :content="waitReviewCount" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  Swipe, SwipeItem, List, Toast,
} from 'vant';
import CityPosition from '@/components/city-position';
import EmptyData from '@/components/empty-data';
import api from '@/api';
import WxTool from '@/utils/wx-tool';
import MedicineSelect from '../../components/medicine-select/index.vue';
import InfusionCenterItem from '../../components/infusion-center-item/index.vue';

export default {
  components: {
    List,
    Swipe,
    SwipeItem,
    CityPosition,
    EmptyData,
    MedicineSelect,
    InfusionCenterItem,
  },
  filters: {
    formatMoney (num) {
      return num.toFixed(2);
    },
  },
  data () {
    return {
      // 区分患者端还是输注中心端
      client: '', // patient: 患者, center: 中心
      infusionCenterList: [],
      cityName: '',
      swipeData: [],
      current: 0,
      indicatorTotal: 0,
      storeList: [],
      loading: false,
      finished: false,
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      showPicker: false,
      drugList: [],
      selectedDrug: {},
      // drugColumns: [],
      waitReviewCount: 0,
      latitude: '',
      longitude: '',
      centerName: '',
    };
  },
  computed: {
  },
  watch: {
  },
  async created () {
    this.selectedDrug = JSON.parse(sessionStorage.getItem('homeMedicine') || '{}');
    if (!localStorage.getItem('userInfo')) {
      // 如果没有用户信息，那么默认展示患者端逻辑
      this.client = 'patient';
    } else {
      // 根据缓存的用户信息，区分角色
      this.client = Number(JSON.parse(localStorage.getItem('userInfo') || '{}').userRole) === 0 ? 'patient' : 'center';
    }

    if (this.client === 'patient') {
      // 患者端 中心列表 & 商品列表
      if ((sessionStorage.getItem('longitude') && sessionStorage.getItem('latitude')) || sessionStorage.getItem('cityName')) {
        // 定位过了，不需要再定位
        this.latitude = sessionStorage.getItem('latitude');
        this.longitude = sessionStorage.getItem('longitude');
        this.cityName = sessionStorage.getItem('cityName') || '未定位';
        this.getInfusionList();
      } else {
        this.handleLocation();
      }
      // this.getDrugList();
    } else {
      // 中心端：待审核预约数量
      this.getApplyWaitReviewCount();
      // 头部中心名称
      this.centerName = JSON.parse(localStorage.getItem('userInfo') || '{}').infusionCenterName;
    }
  },
  methods: {
    handleClear () {
      this.selectedDrug = {}
      this.getInfusionList();
    },
    handleClickSelectDrug () {
      this.$router.push({
        path: '/selectMedicine',
        query: {
          isApply: false,
        },
      });
    },
    handleToList () {
      this.$router.push({
        path: '/infusion',
        query: {
          active: '1',
        },
      });
    },
    handleLocation () {
      const instance = WxTool.getInstance();
      // 定位
      instance.getLocation((res) => {
        if (res.longitude && res.latitude) {
          this.longitude = res.longitude;
          this.latitude = res.latitude;
          sessionStorage.setItem('longitude', res.longitude);
          sessionStorage.setItem('latitude', res.latitude);

          instance.getCurrentCity(res.latitude, res.longitude, (cityNameRes) => {
            this.cityName = cityNameRes;
            sessionStorage.setItem('cityName', cityNameRes);
            this.getInfusionList();
          });
        } else {
          this.getInfusionList();
        }
      });
    },
    // handleConfirmDrug (drug) {
    //   const res = this.drugList.find(item => {
    //     return String(item.productId) === String(drug.value)
    //   })
    //   if (res) {
    //     this.selectedDrug = res
    //     this.getInfusionList()
    //   }
    //   if (drug.value === 0) {
    //     // 全部
    //     this.selectedDrug = {}
    //     this.getInfusionList()
    //   }
    //   this.showPicker = false;
    // },

    // 预约记录
    handleToRecord () {
      this.$router.push({
        path: this.client === 'patient' ? '/applyList' : '/infusion',
      });
    },

    // 选择城市
    handleSelectCity () {
      this.$router.push('/city');
    },

    // 获取待审核数量
    async getApplyWaitReviewCount () {
      try {
        const res = await api.getCenterCount({
          infusionCenterCode: JSON.parse(localStorage.getItem('userInfo') || '{}').infusionCenterCode,
          pageNumber: 1,
          pageSize: 100000,
          status: ['1'],
        });
        this.waitReviewCount = res.data.auditCount;
      } catch (error) {
        console.log('error');
      }
    },

    // 获取中心列表
    async getInfusionList () {
      const res = await api.getInfusionList({
        cityName: this.cityName,
        latitude: this.latitude,
        longitude: this.longitude,
        pageNumber: 1,
        pageSize: 1000,
        // 选中的药品
        productId: this.selectedDrug.productId,
      });

      if (res.data && res.data.list) {
        this.infusionCenterList = res.data.list;
      }
    },

    // 点击预约申请了
    handleApply (obj) {
      this.$router.push({
        path: '/appointmentApply',
        query: {
          id: obj.infusionCenterCode,
        },
      });
    },

    // 中心详情
    handleDetail (obj) {
      this.$router.push({
        path: '/center-detail',
        query: {
          id: obj.infusionCenterCode,
        },
      });
    },

    // 用户中心
    handleUserCenter () {
      this.$router.push('/usercenter');
    },

    // 查看预约输注流程
    handleShowProcess () {
      this.$router.push('/instructions');
    },

    // 搜索中心
    handleClickSearch () {
      this.$router.push('/search-center');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@styles/variables.scss';
.container {
  height: 100%;
  overflow: hidden;

  /deep/ .van-list__finished-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
    width: auto;
    font-size: 11px;
    font-family: $font-family-PF-R;
    font-weight: 400;
    color: #babfc9;
    line-height: 16px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      margin: 0 8px;
      width: 40px;
      height: 1px;
      background: #dcdfe4;
      color: red;
    }
  }
}
.header {
  position: relative;
  height: 105px;
  background: url('./bg.png') no-repeat;
  background-size: contain;
  overflow: hidden;

  .center_name {
    position: absolute;
    top: 18px;
    left: 18px;
    height: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .city_name {
    position: absolute;
    top: 18px;
    left: 18px;
    // width: 28px;
    height: 20px;
    font-size: 14px;
    // font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
    &::after {
      position: absolute;
      top: 4px;
      // left: 3px;
      margin-left: 3px;
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      background-image: url('./arrow_down@2x.png');
      background-size: 12px 12px;
    }
  }

  .search {
    position: absolute;
    top: 14px;
    right: 18px;
    width: 250px;
    height: 28px;
    background: #f5f7fa;
    border-radius: 100px;
    .magnifier {
      position: absolute;
      top: 4px;
      left: 12px;
      width: 20px;
      height: 20px;
    }
    .tip {
      position: absolute;
      top: 4px;
      left: 40px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #babfc9;
      line-height: 20px;
    }
  }

  .process {
    position: absolute;
    top: 55px;
    left: 13px;
    right: 14px;
    width: 348px;
    height: 50px;
    background: #cfe1fd;
    border-radius: 6px;

    .process_img {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 26px;
      height: 26px;
    }

    .tip {
      position: absolute;
      top: 15px;
      // right: 32px;
      left: 48px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1a3377;
      line-height: 20px;
    }
    .arrow_right {
      position: absolute;
      top: 20px;
      right: 10px;
      width: 12px;
      height: 12px;
    }
  }
}

.entrance {
  margin-top: 8px;
  margin-left: 13px;
  margin-right: 13px;
  display: flex;
  justify-content: space-between;
  .item {
    position: relative;
    width: 170px;
    height: 80px;
    // background: #ffffff;
    background: linear-gradient(49deg, #f3f7ff 0%, #e2ebfd 100%);
    border-radius: 6px;
    .title {
      position: relative;
      left: 18px;
      top: 30px;
      height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #1a3377;
      line-height: 22px;
    }
    .record {
      position: absolute;
      top: 20px;
      right: 18px;
      width: 40px;
      height: 40px;
    }

    .usercenter {
      position: absolute;
      top: 20px;
      right: 18px;
      width: 40px;
      height: 40px;
    }
  }
}

.list {
  padding-left: 14px;
  padding-right: 14px;
  .drug_select {
    margin-top: 10px;
    // margin-left: 13px;
    // margin-right: 13px;
    margin-bottom: 10px;
    .line {
      display: flex;
      .title {
        flex: 1;
        // width: 56px;
        position: relative;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;

        &::after {
          position: absolute;
          top: 5px;
          // left: 3px;
          margin-left: 7px;
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          background-image: url('./arrow_down_dark@2x.png');
          background-size: 10px 10px;
        }
      }
      .clear {
        width: 60px;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #2457df;
        line-height: 20px;
      }
    }

    .empty_img {
      width: 100%;
    }
  }
  .empty_text {
    text-align: center;
    color: #aaa;
  }
}

.review_wrapper {
  margin-top: 10px;
  padding: 0 14px;
  width: 100%;
  height: 84px;

  .title {
    height: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    font-style: normal;

    &::before {
      content: '';
      display: inline-block;
      width: 4px;
      height: 12px;
      background: #2457df;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
  .review {
    position: relative;
    margin-top: 14px;
    width: 170px;
    height: 50px;
    background: #ffffff;
    border-radius: 8px;
    .logo {
      position: absolute;
      top: 10px;
      left: 17px;
      width: 30px;
      height: 30px;
    }
    .text {
      position: absolute;
      top: 14px;
      left: 55px;
      height: 22px;
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      font-style: normal;
    }

    .badge_wrapper {
      display: inline-block;
      position: absolute;
      top: 17px;
      right: 18px;
    }
  }
}
.van-list {
  margin-top: -77px;
  height: calc(100vh - 60px);
  overflow: scroll;
}
.classify {
  width: 351px;
  height: 183px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(54, 121, 240, 0.1);
  border-radius: 6px;
}
.my_swipe {
  .van-swipe-item {
    min-height: 183px;
  }

  .indicator_wrapper {
    position: absolute;
    bottom: 11px;
    width: 100%;
    text-align: center;

    .indicator {
      display: inline-block;
      width: 20px;
      height: 2px;
      background: #e4e4e4;
      border-radius: 1px;

      &.active {
        background: #f76d32;
      }
    }
  }
}
.cell_wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;

  .cell {
    margin-top: 12px;
    width: 64px;
    text-align: center;

    &:nth-child(5n) {
      margin-right: 0;
    }

    .img {
      width: 44px;
      height: 44px;
    }

    .title {
      font-size: 12px;
      font-weight: 400;
      color: $--color-title;
      line-height: 1;
    }
  }
}
.card {
  width: 100%;
  // height: 252px;
  padding-bottom: 8px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(54, 121, 240, 0.1);
  border-radius: 6px;

  .store {
    display: flex;
    padding: 16px 16px 0;

    .img {
      width: 65px;
      height: 65px;
      border-radius: 4px;
    }

    .info {
      padding-left: 8px;

      .title {
        margin-bottom: 4px;
        font-size: 16px;
        font-family: $font-family-PF-S;
        font-weight: 600;
        color: $--color-title;
        line-height: 22px;
      }

      .location {
        display: flex;

        .address {
          flex: 1;
          font-size: 12px;
          font-family: $font-family-PF-R;
          font-weight: 400;
          color: $--color-title;
          line-height: 17px;
        }

        &::before {
          content: '';
          margin-right: 4px;
          width: 16px;
          height: 16px;
          background: url('~@img/icon_location.png') no-repeat;
          background-size: cover;
        }
      }
    }
  }

  .drugs {
    display: flex;
    padding: 0 8px;

    .drug {
      padding: 0 8px;
      // flex: 1;
      width: 108px;

      .img_wrapper {
        width: 100%;
        margin-top: 15px;
        margin-bottom: 11px;

        .img {
          position: relative;
          margin: 0 auto;
          // width: 68px;
          // height: 68px;
          width: 100%;
          height: 92px;
          text-align: center;
          background: #edf1f8;

          img {
            width: 100%;
            height: 100%;
            // max-height: 68px;
            max-width: 100%;
            border-radius: 4px;
          }

          .tag {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 44px;
            height: 16px;
            line-height: 16px;
            color: #fff;
            text-align: center;
            background: url('~@img/tag_bg.png') no-repeat;
            background-size: contain;
          }
        }
      }

      .info {
        margin-bottom: 6px;
        min-height: 34px;
        font-size: 12px;
        font-weight: 500;
        color: $--color-title;
        line-height: 17px;
      }

      .price {
        margin-bottom: 8px;
        font-size: 14px;
        font-family: $font-family-AV-M;
        font-weight: 500;
        color: #e94947;
        line-height: 19px;
      }
    }
  }
}

/deep/ .van-picker-column__item .van-ellipsis {
  font-size: 14px;
}
</style>
