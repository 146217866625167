<template>
  <div class="wrapper">
    <div class="agreement">
      <p class="bold">
        在您向本平台提交账户注销申请前，请您仔细阅读本须知。若您对本须知有疑问，或对账户注销有请求、疑问或投诉，请拨打客服热线【400-0523-990】。
      </p>
      <p class="bold">重要提示：</p>
      <p class="bold">
        1.由于账户注销后，会产生如本须知所示的各种后果以及可能对您的权益造成损害、给您造成损失，因此，请您在认真阅读本须知以及充分评估相应的后果后，再进行账户注销的相关操作。
      </p>
      <p class="bold">
        2.账户注销并不代表您在该账户注销前的所有账户行为和相关责任得到豁免或减轻。账户注销后，您仍然需为您注销账户前该账户项下的全部行为、事项承担全部责任。
      </p>

      <p class="bold">
        3.您向本平台提交账户注销申请后，本平台将对您账户的相关情况进行相应核查，仅在通过核查确认符合账户注销条件的情况下，您的账户方能注销成功。
      </p>
      <p class="bold">
        一、提交账户注销申请为不可逆操作。为保证您的账户、财产安全，在您提交账户注销请求前，请先确认符合以下条件：
      </p>
      <p>1.提交账户注销请求的为您本人；</p>
      <p>
        2.您申请注销的账户处于稳定且安全的状态（例如：未发生过被盗、被封号、被冻结等风险）；
      </p>
      <p>
        3.您的账户下不存在已预约但未使用完的服务等，不存在未完成的投诉举报或被投诉举报，以及，不存在其他依照法律法规的规定应当履行而尚未履行的义务；
      </p>
      <p>
        4.您账户下的所有数据均已迁出及妥善备份，或您完全放弃保留、找回该等数据的权利。您账户下所有的用户（如有，包括子账户、协作者、消息接收人）已经全部删除；
      </p>
      <p>
        5.您提交账户注销申请，并非为了躲避正在或即将发生的纠纷诉讼或仲裁，或规避主管部门的监管，或绕开法律法规的相关规定等不良意图；
      </p>

      <p>6.在以下情形中，按照法律法规要求，本平台将无法响应您的注销请求：</p>
      <p>a)与国家安全、国防安全有关的；</p>
      <p>b)与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>c)与犯罪侦查、起诉、审判和执行判决等有关的；</p>
      <p>d)有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
      <p>e)响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
      <p>f)涉及商业秘密的。</p>

      <p class="bold">二、账户注销的后果：</p>
      <p class="bold">
        1.如果您在准备注销的账户项下还有尚未使用的权益的情况下发起相应账户注销流程，将视为您主动放弃相应权益，相应尚未使用的权益在您发起注销流程后将作废或无法再使用。
      </p>
      <p>
        2.账户注销后，您将无法再次以该账户登录本平台，无法再使用任何本平台的产品或服务，以及，无法访问控制台或进行其他依赖于该账户权限进行的操作。
      </p>
      <p>
        3.账户注销后，您将无法找回该账户使用过程中您曾经存储、使用等的全部信息、数据等，本平台按照相关法律规定应该保留的相关信息或数据除外。
      </p>
      <p>4.其他可能对您的权益造成损害或给您造成损失的后果。</p>
      <p>
        5.账户注销后，任何第三方均可发起注册原账户的申请。账户新注册成功后，新的账户持有人对账户注销前的所有账户下的行为不承担任何责任。
      </p>
    </div>

    <bottom-btns
      :btns="[
        {
          text: '确定注销',
          type: 'primary',
          eventName: 'logoff',
          disabled: !agreementChecked,
        },
      ]"
      @logoff="handleLogOff"
      :slot="true"
    >
      <div class="agreement_wrapper">
        <van-checkbox v-model="agreementChecked"
          ><span>我已阅读并同意《平台账户注销须知》</span></van-checkbox
        >
      </div>
    </bottom-btns>
  </div>
</template>

<script>
import api from '@/api'
import { Toast, Dialog } from 'vant'
import BottomBtns from '@/components/bottom-btns/index.vue';

export default {
  components: {
    BottomBtns
  },
  data () {
    return {
      agreementChecked: false,
    }
  },
  methods: {
    handleLogOff () {
      Dialog.confirm({
        title: '提示',
        message: '确认注销账号？',
      })
        .then(async () => {
          const res = await api.logoff({
            userId: JSON.parse(localStorage.getItem('userInfo') || '{}').userId
          })

          if (res.code === '0') {
            Toast.success('注销成功')
            localStorage.removeItem('userInfo')
            localStorage.removeItem('userToken')
            this.$router.replace('/login')
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .agreement {
    padding: 10px 10px 100px 10px;
    text-align: left;
    background: #fff;
    font-size: 13px;
    .bold {
      font-weight: 700;
    }
  }
  text-align: center;
  .logo {
    margin: 88px auto 0;
    width: 70px;
    height: 70px;
  }
  .title {
    // width: 80px;
    height: 32px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #f95c17;
    line-height: 32px;
    // text-align: right;
    font-style: normal;
  }
  .tip_wrapper {
    width: 100%;
    height: 66px;
    text-align: center;
    .tip {
      color: #9a9ea8;
      font-size: 14px;
      line-height: 22px;
      font-style: normal;
    }
  }
  .confirm_btn {
    margin: 50px auto 0;
    width: 132px;
    height: 44px;
    background: #f36218;
    border-radius: 40px;
    border: none;
    text-align: center;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    font-style: normal;
  }
  .agreement_wrapper {
    display: block;
    width: 100%;
    height: 34px;
    font-size: 12px;
    padding: 0 10px;
    .agreement {
      color: #4486fb;
      font-weight: 500;
    }
  }
}
</style>